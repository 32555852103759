'use client';

import { ReactNode } from 'react';

import ProductSubmissionProvider from '@shared/contexts/ProductActionsContext';
import useGetOnlineStatus from '@shared/hooks/useGetOnlineStatus';
import useSetUserTimezone from '@shared/hooks/useSetUserTimeZone';
import ReactQueryProvider from '@shared/providers/ReactQueryProvider';
import Web3ModalProvider from '@shared/providers/WagmiProvider';

interface IProps {
  children: ReactNode;
  timeZone?: string;
}

const App = ({ children, timeZone }: IProps) => {
  useGetOnlineStatus();
  useSetUserTimezone(timeZone);
  return (
    <Web3ModalProvider>
      <ProductSubmissionProvider>
        <ReactQueryProvider>{children}</ReactQueryProvider>
      </ProductSubmissionProvider>
    </Web3ModalProvider>
  );
};

export default App;
