// import { cookieStorage, createStorage } from 'wagmi';
import { bsc, bscTestnet, mainnet } from 'wagmi/chains';

import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

import { config } from './config';

export const projectId = config.projectId;

if (!projectId) throw new Error('Project ID is not defined');

const metadata = {
  name: 'AI Marketplace',
  description: 'AI Marketplace',
  url: 'https://ai.aitech.io',
  icons: [
    'https://www.aitech.io/wp-content/uploads/2023/06/cropped-android-chrome-512x512-1-32x32.png',
  ],
};

export const wagmiConfig = defaultWagmiConfig({
  chains: [mainnet, bsc, bscTestnet],
  projectId,
  metadata,
  ssr: true,
  //
  // Causes bug on forum that logs you out on refresh. wagmi store isdisconnected flag stays true when user connects //
  //
  // storage: createStorage({
  //   storage: cookieStorage,
  // }),

  auth: {
    email: false,
  },
});
