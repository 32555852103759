import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { onlineManager } from '@tanstack/react-query';

import { toastSuccess, toastWarning } from '@shared/utils';

function useGetOnlineStatus() {
  useEffect(() => {
    onlineManager.subscribe((isOnline) => {
      if (!isOnline) {
        toastWarning('Check your internet connection', {
          autoClose: false,
          id: 'offline',
        });
      } else {
        toast.dismiss('offline');
        toastSuccess('You are back online', { id: 'online' });
      }
    });
  }, []);
}

export default useGetOnlineStatus;
