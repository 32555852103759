'use client';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import Logo from '@components/logo';

import { SambaNovaLogo } from '@shared/assets';
import { ADMIN, HOME, MAINTENANCE } from '@shared/utils';

import { FOOTER_LINKS, FOOTER_TEXT } from './constants';
import Icons from './socialIcons';

const Footer = () => {
  const pathname = usePathname();
  if (pathname.startsWith(ADMIN) || pathname.startsWith(MAINTENANCE))
    return null;

  return (
    <footer className="z-[2] mt-auto flex w-full flex-col gap-6 border-y border-neutral700 px-4 pb-16 pt-6 sm:px-6 md:p-6">
      <div className="flexCenter relative flex-col gap-12 md:flex-row md:justify-between lg:h-16">
        <div className="flex items-center">
          <Logo
            isOnlyFullLogo
            className="ml-0 w-40"
            isHomepage={pathname === HOME}
          />
        </div>
        <div className="flexCenter md:absoluteCenter flex-row gap-5 xs:gap-12">
          {FOOTER_LINKS.map((link) => (
            <Link
              key={link.id}
              href={link.url}
              target="_blank"
              className="label-small xs:label-large cursor-pointer uppercase text-neutral200 transition-all hover:text-white"
            >
              {link.label}
            </Link>
          ))}
        </div>
        <div className="flex flex-col">
          <Icons className="flex justify-end border-b border-neutral700 pb-2" />
          <Image
            src={SambaNovaLogo.src}
            alt="samba-nova-logo"
            width={158}
            height={24}
            className="mt-2"
          />
        </div>
      </div>
      <div className="h-px w-full bg-green800" />
      <p className="label-small xs:label-large text-center uppercase text-neutral400">
        {FOOTER_TEXT}
      </p>
    </footer>
  );
};

export default Footer;
