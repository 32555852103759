import Link from 'next/link';

import { ISvgImport } from '@interfaces/svg';

interface IProps {
  icon: ISvgImport;
  href: string;
}

const SingleIcon = ({ href, icon: Icon }: IProps) => {
  return (
    <Link href={href} className="group cursor-pointer" target="_blank">
      <Icon className="w-6 text-neutral400 transition-all group-hover:text-white md:w-4.5" />
    </Link>
  );
};

export default SingleIcon;
