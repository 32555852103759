'use client';

import { ReactNode } from 'react';

import { WagmiProvider } from 'wagmi';

import { createWeb3Modal } from '@web3modal/wagmi/react';

import { projectId, wagmiConfig } from '../config/wagmiConfig';

if (!projectId) throw new Error('Project ID is not defined');

createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId,
  enableAnalytics: true,
  enableOnramp: false,
  allowUnsupportedChain: true,
  themeVariables: {
    '--w3m-font-family': 'var(--font-exo)',
    '--w3m-color-mix': '#0C111D',
    '--w3m-color-mix-strength': 20,
  },
});

export default function Web3ModalProvider({
  children,
}: {
  children: ReactNode;
}) {
  return <WagmiProvider config={wagmiConfig}>{children}</WagmiProvider>;
}
