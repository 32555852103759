import { useEffect } from 'react';

import { setUserTimeZone } from '@shared/utils/locationCookie';

function useSetUserTimezone(timeZone?: string) {
  useEffect(() => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone !== currentTimeZone) {
      setUserTimeZone(currentTimeZone);
    }
  }, [timeZone]);
}

export default useSetUserTimezone;
