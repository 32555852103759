'use client';

import { twMerge } from 'tailwind-merge';

import { FOOTER_ICONS } from '../constants';
import SingleIcon from './SingleIcon';

interface IProps {
  className?: string;
}

const SocialIcons = ({ className }: IProps) => {
  return (
    <div className={twMerge('gap-4', className)}>
      {FOOTER_ICONS.map(({ href, icon, id }) => (
        <SingleIcon icon={icon} href={href} key={id} />
      ))}
    </div>
  );
};

export default SocialIcons;
