import { INavItem } from '@components/navigation/types';

import { LinkedInIcon, MediumIcon, TelegramIcon, XIcon } from '@shared/assets';

import { getCurrentYear } from './utils';

export const FOOTER_LINKS: INavItem[] = [
  {
    id: 'privacyPolicy',
    label: 'Privacy Policy',
    url: 'https://docs.ai.aitech.io/privacy-policy',
  },
  {
    id: 'termsOfService',
    label: 'Terms of Use',
    url: 'https://docs.ai.aitech.io/terms-of-service',
  },
  /*{
    id: 'faq',
    label: 'FAQ',
    url: '/',
  },*/
];

export const FOOTER_ICONS = [
  {
    id: 'telegram',
    icon: TelegramIcon,
    href: 'https://t.me/solidusaichat',
  },
  {
    id: 'x',
    icon: XIcon,
    href: 'https://twitter.com/AITECHio?ref_src=twsrc%5Etfw',
  },
  {
    id: 'medium',
    icon: MediumIcon,
    href: 'https://medium.com/@solidusaitech',
  },
  {
    id: 'linkedIn',
    icon: LinkedInIcon,
    href: 'https://www.linkedin.com/company/solidus-ai-tech-ltd',
  },
];

export const FOOTER_TEXT = `Copyright @${getCurrentYear()} Solidus AITech`;
